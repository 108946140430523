import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from './Link'
import media from '../utils/media'
import { useIntl } from '../utils/IntlContext'
import { getLocalePathPrefix } from '../utils/locales'

const AuthorBanner = styled.div`
  padding: calc(var(--spacing-md) + var(--nav-header-height)) var(--spacing-md) var(--spacing-md);
  background-color: var(--color-background-pink);
`

const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: calc(min(100vw, var(--max-width)) - var(--spacing-md) * 2);
  gap: var(--spacing-xsm);
  margin: auto;
`

const AuthorLinkWrapper = styled.div`
  display: flex;
  align-self: flex-start;
`

const StyledLink = styled(Link)`
  padding-bottom: 2px;

  &:last-child {
    color: var(--color-primary);
  }

  &:not(:last-child) {
    border-bottom: 2px solid var(--color-primary);
  }
`

const Divider = styled.div`
  &:after {
    content: '/';
    padding: 0 5px;
  }
`

const Avatar = styled(GatsbyImage)`
  border-radius: 50%;
  max-height: 96px;
  max-width: 96px;
`

const Title = styled.p`
  text-align: center;
  margin-bottom: 0;
`

const AuthorName = styled.h1`
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  margin-bottom: var(--spacing-xxxsm);

  ${media.md`
    font-size: 32px;
    line-height: 32px;
  `}
`

const Bio = styled.div`
  max-width: 625px;
  text-align: center;

  a {
    color: var(--color-primary);
  }

  p {
    margin-bottom: var(--spacing-xsm);
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`

const SubHeadingWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-direction: column;
  max-width: var(--max-width);
  margin: auto auto calc(var(--spacing-md) - 40px - var(--spacing-md)); // Collapse margin with the next section
  padding: var(--spacing-md);

  ${media.sm`
    flex-direction: row;
  `}
`

const SubHeading = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;

  ${media.sm`
    font-size: 32px;
    margin: unset;
  `}
`

const TotalPosts = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;

  ${media.sm`
    margin: unset;
  `}
`

const BlogAuthorBanner = ({
  avatar,
  name,
  title,
  bio,
  blogBasePath,
  blogAuthorBasePath,
  postsDisplayed,
  totalPosts,
  currentPage,
}) => {
  const { locale, t } = useIntl()

  const authorFirstName = name.split(' ')[0]

  const lowerRangeDisplayed = currentPage > 1 ? 10 * (currentPage - 1) : 10
  const upperRangeDisplayed = 10 * currentPage >= totalPosts ? totalPosts : 10 * currentPage
  const postRange = currentPage === 1 ? postsDisplayed : `${lowerRangeDisplayed} - ${upperRangeDisplayed}`

  return (
    <>
      <AuthorBanner>
        <InnerContent>
          <AuthorLinkWrapper>
            <StyledLink to={getLocalePathPrefix(locale)}>{t('homepage')}</StyledLink>
            <Divider />
            <StyledLink to={blogBasePath}>{t('blog')}</StyledLink>
            <Divider />
            <StyledLink to={blogAuthorBasePath}>{name}</StyledLink>
          </AuthorLinkWrapper>
          <Avatar
            image={getImage(avatar.localFile)}
            alt={name}
            loading="eager"
          />
          <div>
            <AuthorName>{name}</AuthorName>
            <Title>{title}</Title>
          </div>
          <Bio dangerouslySetInnerHTML={{ __html: bio.html }} />
        </InnerContent>
      </AuthorBanner>
      <SubHeadingWrapper>
        <SubHeading>{t('author_posts', { authorFirstName })}</SubHeading>
        <TotalPosts>{t('results_displayed', { postRange, totalPosts })}</TotalPosts>
      </SubHeadingWrapper>
    </>
  )
}

export default BlogAuthorBanner
