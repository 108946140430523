import { graphql } from 'gatsby'
import BlogAuthorBanner from '../components/BlogAuthorBanner'
import BlogListing from '../components/BlogListing'
import GatsbyHead from '../components/GatsbyHead'
import LayoutWrapper from '../components/LayoutWrapper'
import Pagination from '../components/Pagination'
import Section from '../components/Section'
import Wrapper from '../components/Wrapper'
import { useIntl } from '../utils/IntlContext'
import { getLocalePathPrefix, slugify, urlPathJoin } from '../utils/locales'

export const query = graphql`
  query BlogAuthor($contentful_id: String!, $skip: Int!, $limit: Int!, $locale: String!) {
    contentfulReactPage(contentful_id: { eq: "1V51KAm4h66KiLS1F7scdV" }, node_locale: { eq: $locale }) {
      id
      contentful_id
      slug
      node_locale
      languages
      meta_title
      meta_description
    }
    allContentfulBlogPost(
      filter: {
        slug: { nin: [null, ""] }
        title: { nin: [null, ""] }
        content: { childMarkdownRemark: { html: { nin: [null, ""] } } }
        node_locale: { eq: $locale }
        author: { contentful_id: { eq: $contentful_id } }
      }
      sort: { fields: date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      totalCount
      edges {
        node {
          ...BlogListingItem
        }
      }
    }
    ...BlogCta
    author: contentfulTeamMember(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      title
      name
      bio {
        childMarkdownRemark {
          html
        }
      }
      photo {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 96, height: 96, layout: FIXED)
          }
        }
      }
    }
  }
`

const BlogAuthor = ({
  pageContext: { translatedPages, numPages, currentPage },
  data: {
    contentfulReactPage: { slug },
    allContentfulBlogPost,
    blogCtaCardImage,
    blogCtaCardLink,
    author,
  },
}) => {
  const { locale } = useIntl()
  const blogBasePath = urlPathJoin(getLocalePathPrefix(locale), slug)
  const blogAuthorBasePath = urlPathJoin(blogBasePath, slugify(author.name))

  return (
    <LayoutWrapper translatedPages={translatedPages}>
      <BlogAuthorBanner
        avatar={author.photo}
        name={author.name}
        title={author.title}
        bio={author.bio.childMarkdownRemark}
        blogBasePath={blogBasePath}
        blogAuthorBasePath={blogAuthorBasePath}
        postsDisplayed={allContentfulBlogPost.edges.length}
        totalPosts={allContentfulBlogPost.totalCount}
        currentPage={currentPage}
      />
      <Section
        className="section"
        align="center"
        bgColor="White"
        hasComponent>
        <Wrapper>
          <BlogListing
            parentBasePath={blogBasePath}
            blogPosts={allContentfulBlogPost.edges.map((edge) => edge.node)}
            blogCtaCardImage={blogCtaCardImage}
            blogCtaCardLink={blogCtaCardLink}
          />
          <Pagination
            basePath={blogAuthorBasePath}
            pageCount={numPages}
            currentPage={currentPage}
          />
        </Wrapper>
      </Section>
    </LayoutWrapper>
  )
}

/** @type {import('gatsby').HeadFC} */
export const Head = ({
  pageContext: { canonicalUrl, translatedPages },
  data: {
    contentfulReactPage: { meta_title, meta_description },
  },
}) => {
  return (
    <GatsbyHead
      pageTitle={meta_title}
      metaDescription={meta_description}
      canonicalUrl={canonicalUrl}
      translatedPages={translatedPages}
    />
  )
}

export default BlogAuthor
